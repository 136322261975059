<template>
  <div style="padding: 20px">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>
      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">筛选条件</div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent
            >
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label-width="120px" label="提现单号">
                    <el-input
                      v-model="where.name"
                      placeholder="提现单号"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12" style="text-align: center">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList"
                      >查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">数据列表</div>
              <span
                style="
                  font-size: 18px;
                  font-weight: 700;
                  color: #4e4e4e;
                  margin-left: 30px;
                "
                >累计提现：{{ zongshu }}</span
              >
              <span
                style="font-size: 18px; font-weight: 700; color: #fea837"
                >{{
              }}</span>
              <span
                style="
                  font-size: 18px;
                  font-weight: 700;
                  color: #4e4e4e;
                  margin-left: 20px;
                "
                >可用金额：{{ in_hand }}</span
              >
              <span
                style="font-size: 18px; font-weight: 700; color: #4e4e4e"
                >{{
              }}</span>
            </div>
            <div></div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :customsFromWhere="where"
              :total="total"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >
              <template v-slot:operationSlot="{ scope }">
                <div v-if="scope.row.status == 0">
                  <el-link
                    :underline="false"
                    type="warning"
                     v-auths="[
                    `${$config.uniquePrefix}finance:sharedExtract:status`,
                  ]"
                    @click="affirm(scope.row)"
                    >通过</el-link
                  >
                  <el-link
                    :underline="false"
                    type="danger"
                     v-auths="[
                    `${$config.uniquePrefix}finance:sharedExtract:status`,
                  ]"
                    @click="refuse(scope.row)"
                    >拒绝</el-link
                  >
                </div>
              </template>
            </my-table>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
// 引入自定义表格组件
import MyTable from "@/components/MyTable";
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";

// 权限
import { ShowTable } from "@/utils";
// 引入的接口
import {
  shared_extract,
  shared_extractcount,
  shared_extractreject,
  shared_extractpass,
} from "@/api/operate";

export default {
  // 组件生效
  components: {
    MyTable,
    PackageSortElement,
  },
  // Data数据
  data() {
    return {
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      dialogVisible: false,
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading: true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true,
        },
        {
          label: "状态",
          prop: "status_label",
          isShow: true,
        },
        {
          label: "提现单号",
          prop: "odd",
          isShow: true,
        },
        {
          label: "提现方式",
          prop: "extract_type",
          isShow: true,
        },
        {
          label: "提现用户名",
          prop: "real_name",
          isShow: true,
        },
        {
          label: "提现金额",
          prop: "extract_price",
          isShow: true,
        },
        {
          label: "提现时间",
          prop: "withdraw_time",
          isShow: true,
        },
        {
          label: "提现失败原因",
          prop: "fail_msg",
          isShow: true,
        },
        {
          label: "操作",
          width: "200",
          prop: "operationSlot",
          slot: "operationSlot",
          isShow: true,
        },
      ],

      // 列表设置弹窗
      ListSetupShow: false,
      zongshu: 0,
      in_hand: 0,
    };
  },
  // // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getList();
  },
  // updated(){
  //   this.getList();
  // },
  filters: {
    formatDate(value, format) {
      if (!value) return "";
      return dayjs(value*1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  //  activated() {
  //   console.log("666666");
  //   this.getList();
  // },
  // 方法集合
  methods: {
    getList() {
      shared_extractcount().then((res) => {
        this.zongshu = res.data.total;
        this.in_hand = res.data.in_hand;
      });
      // console.log("666666");
      shared_extract(this.where)
        .then(async (res) => {
          //   console.log(res.data);
          this.total = res.data.count;
          this.List = res.data.list;
          this.loading = false;
        })
        .catch((res) => {
          this.$Message.error(res.msg);
          this.loading = false;
        });
    },
    affirm(row) {
      // console.log(row);
      let that = this;
      this.$utils.confirm(
        "确定要执行此操作吗？",
        function () {
          // 执行一些需要的逻辑
          shared_extractpass(row.id)
            .then((res) => {
              // console.log(res)
              if (res.code == 200) {
                that.$message.success(res.msg);
                that.getList();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch((err) => {
              that.$message.error(err.msg);
            });
        },
        "确定",
        "warning"
      );
    },
    refuse(row) {
      let that = this;
      this.$utils.confirm(
        "确定要执行此操作吗？",
        function () {
          // 执行一些需要的逻辑
          shared_extractreject(row.id)
            .then((res) => {
              // console.log(res)
              if (res.code == 200) {
                that.$message.success(res.msg);
                that.getList();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch((err) => {
              that.$message.error(err.msg);
            });
        },
        "确定",
        "warning"
      );
    },
    closet() {
      this.dialogVisible = false;
      this.domain = "";
    },
    // 查询条件
    QueryList() {
      this.getList();
    },
    // 清空
    empty() {
      this.where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.getList();
    },
    // 当前页数改变事件
    currentChange(val) {
      // console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      // console.log(selection)
      // console.log("选择的所有数据行", selection)
      // console.log("当前选择的数据行", row)
    },
    ChangeSize(val) {
      // console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList();
    },
    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 点击列表设置
    ListSetup() {
      this.ListSetupShow = true;
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 20px;
}

.condition {
  .condition_t {
    display: flex;
    align-items: center;
  }
  .condition_t_icon {
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text {
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
.flex {
  display: flex;
  align-items: center;
}
.w-50 {
  width: 400px;
}
.youbianjudingshirenwu {
  margin-left: 15px;
}
.zuobianjudingshirenwu {
  margin-right: 15px;
}
</style>

import request from '@/utils/request'

/**
 * 运营 订单审核配置 列表
 * @returns {*}
 */
export function getorder_audits(params) {
  return request({
    url: 'operation/order_audits',
    method: 'get',
    params
  })
}
/**
 * 运营 订单审核配置 详情
 * @returns {*}
 */
export function Detailsorder_audits(id, params) {
  return request({
    url: `operation/order_audits/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运营 订单审核配置 获取配置
 * @returns {*}
 */
export function getConfig(params) {
  return request({
    url: 'operation/order_audit/config',
    method: 'get',
    params
  })
}
/**
 * 运营 订单审核配置 添加
 * @returns {*}
 */
export function Addorder_audits(data) {
  return request({
    url: `operation/order_audits`,
    method: 'post',
    data
  })
}
/**
 * 运营 订单审核配置 修改
 * @returns {*}
 */
export function Editorder_audits(id, data) {
  return request({
    url: `operation/order_audits/${id}`,
    method: 'put',
    data
  })
}
/**
 * 运营 订单审核配置 删除
 * @returns {*}
 */
export function Delorder_audits(id) {
  return request({
    url: `operation/order_audits/${id}`,
    method: 'delete',
  })
}


/**
 * 运营 示例图配置 列表
 * @returns {*}
 */
export function getsamples(params) {
  return request({
    url: 'system/sample_graph',
    method: 'get',
    params
  })
}

/**
 * 运营 示例图配置 详情
 * @returns {*}
 */
export function Detailssample_graph(id, params) {
  return request({
    url: `system/sample_graph/${id}`,
    method: 'get',
    params
  })
}


/**
 * 运营 示例图配置 添加
 * @returns {*}
 */
export function Addsamples(data) {
  return request({
    url: `system/sample_graph`,
    method: 'post',
    data
  })
}
/**
 * 运营 示例图配置 修改
 * @returns {*}
 */
export function Editsamples(id, data) {
  return request({
    url: `system/sample_graph/${id}`,
    method: 'put',
    data
  })
}
/**
 * 运营 示例图配置 删除
 * @returns {*}
 */
export function Delsamples(id) {
  return request({
    url: `system/sample_graph/${id}`,
    method: 'delete',
  })
}



/**
 * 运营 服务项目配置 列表
 * @returns {*}
 */
export function getservices_config(params) {
  return request({
    url: 'operation/services_configs',
    method: 'get',
    params
  })
}
/**
 * 运营 服务项目配置 添加
 * @returns {*}
 */
export function Addservices_config(data) {
  return request({
    url: `operation/services_configs`,
    method: 'post',
    data
  })
}
/**
 * 运营 服务项目配置 修改
 * @returns {*}
 */
export function Editservices_config(id, data) {
  return request({
    url: `operation/services_configs/${id}`,
    method: 'put',
    data
  })
}
/**
 * 运营 服务项目配置 详情
 * @returns {*}
 */
export function Detailsservices_config(id, params) {
  return request({
    url: `operation/services_configs/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运营 服务项目配置 删除
 * @returns {*}
 */
export function Delservices_config(id) {
  return request({
    url: `operation/services_configs/${id}`,
    method: 'delete',
  })
}

/**
 * 运营 服务项目配置 获取C端服务项目配置接口
 * @returns {*}
 */
export function consumer_services_config(params) {
  return request({
    url: 'operation/services_config/consumer_services_config',
    method: 'get',
    params
  })
}

/**
 * 运营 服务项目配置 C端服务项目配置接口
 * @returns {*}
 */
export function saveconsumer_services_config(data) {
  return request({
    url: `operation/services_config/consumer_services_config`,
    method: 'post',
    data
  })
}



/**
 * 运营 默认拍照模板 列表
 * @returns {*}
 */
export function getphoto_template_select(params) {
  return request({
    url: 'operation/photo_template_select',
    method: 'get',
    params
  })
}


/**
 * 运营 拍照模板 列表
 * @returns {*}
 */
export function getphoto_templates(params) {
  return request({
    url: 'operation/photo_templates',
    method: 'get',
    params
  })
}
/**
 * 运营 拍照模板 添加
 * @returns {*}
 */
export function Addphoto_templates(data) {
  return request({
    url: `operation/photo_templates`,
    method: 'post',
    data
  })
}
/**
 * 运营 拍照模板 修改
 * @returns {*}
 */
export function Editphoto_templates(id, data) {
  return request({
    url: `operation/photo_templates/${id}`,
    method: 'put',
    data
  })
}
/**
 * 运营 拍照模板 详情
 * @returns {*}
 */
export function Detalisphoto_templates(id, params) {
  return request({
    url: `operation/photo_templates/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运营 拍照模板 删除
 * @returns {*}
 */
export function Delphoto_templates(id) {
  return request({
    url: `operation/photo_templates/${id}`,
    method: 'delete',
  })
}




/**
 * 运营 自动结算 列表
 * @returns {*}
 */
export function getauto_balances(params) {
  return request({
    url: 'operation/auto_balances',
    method: 'get',
    params
  })
}
/**
 * 运营 自动结算 添加
 * @returns {*}
 */
export function Addauto_balances(data) {
  return request({
    url: `operation/auto_balances`,
    method: 'post',
    data
  })
}
/**
 * 运营 自动结算 修改
 * @returns {*}
 */
export function Editauto_balances(id, data) {
  return request({
    url: `operation/auto_balances/${id}`,
    method: 'put',
    data
  })
}
/**
 * 运营 自动结算 删除
 * @returns {*}
 */
export function Delauto_balances(id) {
  return request({
    url: `operation/auto_balances/${id}`,
    method: 'delete',
  })
}


/**
 * 运营 对账资料 开票资料 列表
 * @returns {*}
 */
export function getinvoice(params) {
  return request({
    url: `operation/reconciliation/invoice`,
    method: 'get',
    params
  })
}
/**
 * 运营 对账资料 收款资料 列表
 * @returns {*}
 */
export function getproceeds(params) {
  return request({
    url: 'operation/reconciliation/proceeds',
    method: 'get',
    params
  })
}
/**
 * 运营 对账资料 收款资料 添加
 * @returns {*}
 */
export function Addproceeds(data) {
  return request({
    url: `operation/reconciliation/proceeds`,
    method: 'post',
    data
  })
}
/**
 * 运营 对账资料 收款资料 修改
 * @returns {*}
 */
export function Editproceeds(id, data) {
  return request({
    url: `operation/reconciliation/proceeds/${id}`,
    method: 'put',
    data
  })
}
/**
 * 运营 对账资料 收款资料 删除
 * @returns {*}
 */
export function Delproceeds(id) {
  return request({
    url: `operation/reconciliation/proceeds/${id}`,
    method: 'delete',
  })
}




/**
 * 运营 知识库 列表
 * @returns {*}
 */
export function getknowledge(params) {
  return request({
    url: `operation/knowledge`,
    method: 'get',
    params
  })
}
/**
 * 运营 知识库 详情
 * @returns {*}
 */
export function Detailsknowledge(id, params) {
  return request({
    url: `operation/knowledge/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运营 知识库 状态修改
 * @returns {*}
 */
export function Statusset_status(id, params) {
  return request({
    url: `operation/knowledge/${id}/status`,
    method: 'get',
    params
  })
}
/**
 * 运营 知识库 添加
 * @returns {*}
 */
export function Addknowledge(data) {
  return request({
    url: `operation/knowledge`,
    method: 'post',
    data
  })
}
/**
 * 运营 知识库 修改
 * @returns {*}
 */
export function Editknowledge(id, data) {
  return request({
    url: `operation/knowledge/${id}`,
    method: 'put',
    data
  })
}
/**
 * 运营 知识库 删除
 * @returns {*}
 */
export function Delknowledge(id) {
  return request({
    url: `operation/knowledge/${id}`,
    method: 'delete',
  })
}
/**
 * 运营 知识库 获取分类列表
 * @returns {*}
 */
export function getknowledge_category(params) {
  return request({
    url: `operation/knowledge_category`,
    method: 'get',
    params
  })
}
/**
 * 运营 知识库分类 添加
 * @returns {*}
 */
export function Addknowledge_category(data) {
  return request({
    url: `operation/knowledge_category`,
    method: 'post',
    data
  })
}
/**
 * 运营 知识库分类 删除
 * @returns {*}
 */
export function Delknowledge_category(id) {
  return request({
    url: `operation/knowledge_category/${id}`,
    method: 'delete',
  })
}

/**
 * 运营 会员设置 获取配置
 * @returns {*}
 */
export function getmember_center(params) {
  return request({
    url: `operation/member_centers/config`,
    method: 'get',
    params
  })
}

/**
 * 运营 会员设置 获取详情
 * @returns {*}
 */
export function Detailsmember_centers(params) {
  return request({
    url: `operation/member_centers/details`,
    method: 'get',
    params
  })
}
///operation/member_centers/config
/**
 * 运营 会员设置 保存
 * @returns {*}
 */
export function Savemember_centers(data) {
  return request({
    url: `operation/member_centers/submit`,
    method: 'post',
    data
  })
}
/**
 * 运营 会员设置 删除
 * @returns {*}
 */
export function Delmember_centers(id) {
  return request({
    url: `operation/member_centers/delete/${id}`,
    method: 'delete',
  })
}



/**
 * 运营 服务配置 获取配置
 * @returns {*}
 */
export function getget_service_setup(params) {
  return request({
    url: `operation/synthetical/get_service_setup`,
    method: 'get',
    params
  })
}
/**
 * 运营 服务配置 保存
 * @returns {*}
 */
export function Saveset_service_setup(data) {
  return request({
    url: `operation/synthetical/set_service_setup`,
    method: 'put',
    data
  })
}



/**
 * 运营 商城套餐管理 列表
 * @returns {*}
 */
export function getmall_package(params) {
  return request({
    url: 'common/store_list',
    method: 'get',
    params
  })
}
/**
 * @returns {*}
 */
export function getmall_packages(params) {
  return request({
    url: 'operation/mall_packages',
    method: 'get',
    params
  })
}


/**
 * 运营 商城套餐管理 购买
 * @returns {*}
 */
export function store_pay(data) {
  return request({
    url: `common/store_pay`,
    method: 'post',
    data
  })
}

/**
 * 运营 商城套餐管理 添加
 * @returns {*}
 */
export function Addmall_package(data) {
  return request({
    url: `operation/mall_packages`,
    method: 'post',
    data
  })
}
/**
 * 运营 商城套餐管理 修改
 * @returns {*}
 */
export function Editmall_package(id, data) {
  return request({
    url: `operation/mall_packages/${id}`,
    method: 'put',
    data
  })
}
/**
 * 运营 商城套餐管理 状态修改
 * @returns {*}
 */
export function SaveStatus(id, params) {
  return request({
    url: `operation/mall_package/set_status/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运营 商城套餐管理 删除
 * @returns {*}
 */
export function Delmall_package(id) {
  return request({
    url: `operation/mall_packages/${id}`,
    method: 'delete',
  })
}
/**
 * 运营 套餐购买记录 列表
 * @returns {*}
 */
export function getlog(params) {
  return request({
    url: 'operation/mall_package/log',
    method: 'get',
    params
  })
}




/**
 * 运营 短信设置 获取
 * @returns {*}
 */
export function get_note_config(params) {
  return request({
    url: 'operation/synthetical/get_note_config',
    method: 'get',
    params
  })
}
/**
 * 运营 短信设置 保存
 * @returns {*}
 */
export function Saveeset_note_config(data) {
  return request({
    url: `operation/synthetical/set_note_config`,
    method: 'post',
    data
  })
}



/**
 * 运营 推广设置 获取
 * @returns {*}
 */
export function getget_popularize_config(params) {
  return request({
    url: 'operation/synthetical/get_popularize_config',
    method: 'get',
    params
  })
}
/**
 * 运营 短信设置 保存
 * @returns {*}
 */
export function Saveset_popularize_config(data) {
  return request({
    url: `operation/synthetical/set_popularize_config`,
    method: 'post',
    data
  })
}
/**
 * 运营 推广记录 获取
 * @returns {*}
 */
export function getget_popularize(params) {
  return request({
    url: 'operation/get_popularize',
    method: 'get',
    params
  })
}




/**
 * 运营 消息通知 获取
 * @returns {*}
 */
export function getnote(params) {
  return request({
    url: 'operation/note',
    method: 'get',
    params
  })
}
/**
 * 运营 消息通知 通知详情
 * @returns {*}
 */
export function Detalisnote(id, params) {
  return request({
    url: `operation/note/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运营 消息通知 全部已读
 * @returns {*}
 */
export function Setnote_all(params) {
  return request({
    url: 'operation/note_all',
    method: 'get',
    params
  })
}





/**
 * 运营 订单分配 获取
 * @returns {*}
 */
export function getorder_apportion(params) {
  return request({
    url: 'operation/order_apportion',
    method: 'get',
    params
  })
}
/**
 * 运营 订单分配 获取详情
 * @returns {*}
 */
export function Detalisorder_apportion(id, params) {
  return request({
    url: `operation/order_apportion/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运营 订单分配 新增
 * @returns {*}
 */
export function Addorder_apportion(data) {
  return request({
    url: `operation/order_apportion`,
    method: 'post',
    data
  })
}
/**
 * 运营 订单分配 修改
 * @returns {*}
 */
export function Editorder_apportion(id, data) {
  return request({
    url: `operation/order_apportion/${id}`,
    method: 'put',
    data
  })
}



/**
 * 运营 移动端下单价格列表
 * @returns {*}
 */
export function getmobile_price(params) {
  return request({
    url: 'operation/mobile_prices',
    method: 'get',
    params
  })
}
/**
 * 运营 移动端下单价格配置
 * @returns {*}
 */
export function getmobileterminal(params) {
  return request({
    url: 'operation/mobile_config',
    method: 'get',
    params
  })
}
/**
 * 运营 移动端下单价格配置 新增
 * @returns {*}
 */
export function Savemobile_price(data) {
  return request({
    url: `operation/mobile_prices`,
    method: 'post',
    data
  })
}
/**
 * 运营 移动端下单价格配置 修改
 * @returns {*}
 */
export function Editmobile_price(id, data) {
  return request({
    url: `operation/mobile_prices/${id}`,
    method: 'put',
    data
  })
}
/**
 * 运营 移动端下单价格配置 详情
 * @returns {*}
 */
export function Detailsmobile_price(id, params) {
  return request({
    url: `operation/mobile_prices/${id}`,
    method: 'get',
    params
  })
}

/**
 * 运营 移动端下单价格配置 删除
 * @returns {*}
 */
export function Delmobile_price(id) {
  return request({
    url: `operation/mobile_prices/${id}`,
    method: 'delete',
  })
}
/**
 * 运营 移动端下单价格配置 状态修改
 * @returns {*}
 */
export function Savemobile_priceEdit(id, params) {
  return request({
    url: `operation/mobile_price/set_status/${id}`,
    method: 'get',
    params
  })
}

/**
 * 运营 提现设置 获取配置
 * @returns {*}
 */
export function get_withdrawal_setup(params) {
  return request({
    url: 'operation/synthetical/get_withdrawal_setup',
    method: 'get',
    params
  })
}
/**
 * 运营 提现设置 添加
 * @returns {*}
 */
export function set_withdrawal_setup(data) {
  return request({
    url: `operation/synthetical/set_withdrawal_setup`,
    method: 'post',
    data
  })
}


/**
 * 服务商域名列表；
 *
 * @returns {*}
 */
export function getVendordomain(params) {
  return request({
    url: `/facilitator/list`,
    method: 'get',
    params
  })
}


/**
 * 绑定域名；
 *
 * @returns {*}
 */
export function Bounddomain(id, data) {
  return request({
    url: `/facilitator/bind/${id}`,
    method: 'post',
    data
  })
}

/**
 * 解绑域名；
 *
 * @returns {*}
 */
export function unbinddomain(id) {
  return request({
    url: `/facilitator/unbind/${id}`,
    method: 'post'
  })
}

/**
 * 定时任务列表
 *
 * @returns {*}
 */
export function scheduledlist() {
  return request({
    url: `/system/crontab/list`,
    method: 'get'
  })
}

/**
 * 定时任务类型
 *
 * @returns {*}
 */
export function scheduledtype() {
  return request({
    url: `/system/crontab/mark`,
    method: 'get'
  })
}

/**
 * 定时任务详情
 *
 * @returns {*}
 */
export function scheduleddetails(id) {
  return request({
    url: `/system/crontab/info/${id}`,
    method: 'get'
  })
}

/**
 * 定时任务添加编辑
 *
 * @returns {*}
 */
export function addscheduleddetails(id, data) {
  return request({
    url: `/system/crontab/save/${id}`,
    method: 'post',
    data
  })
}



/**
 * 定时任务状态修改
 *
 * @returns {*}
 */
export function updatedingtask(id) {
  return request({
    url: `/system/crontab/open/${id}`,
    method: 'get'
  })
}




/**
 * 删除定时任务
 *
 * @returns {*}
 */
export function deletingtask(id) {
  return request({
    url: `/system/crontab/del/${id}`,
    method: 'delete'
  })
}


/**
 * 申请体验记录列表
 *
 * @returns {*}
 */
export function experience() {
  return request({
    url: `/operation/experience_record`,
    method: 'get'
  })
}

/**
 * 开通申请接口
 *
 * @returns {*}
 */
export function openapplication(id, data) {
  return request({
    url: `/operation/experience_record/${id}`,
    method: 'put',
    data
  })
}


//财务



//平台提现记录
/**
 *
 * 获取平台提现列表接口
 *
 * @returns {*}
 */

export function withdrawalapplication(params) {
  return request({
    url: `/finance/finance_extract/list`,
    method: 'get',
    params
  })
}
///finance/finance_extract/list

/**
 *
 * 获取平台提现统计接口
 *
 * @returns {*}
 */

export function withdrawalstatistics() {
  return request({
    url: `/finance/finance_extract/count`,
    method: 'get',
  })
}

/**
 *
 * 获取平台提现通过接口
 *
 * @returns {*}
 */

export function passid(id) {
  return request({
    url: `/finance/finance_extract/pass/${id}`,
    method: 'get',
  })
}

/**
 *
 * 获取平台提现通过接口
 *
 * @returns {*}
 */

export function refuses(id, data) {
  return request({
    url: `/finance/finance_extract/reject/${id}`,
    method: 'post',
    data
  })
}

/**
 *
 * 获取自营提现列表接口
 *
 * @returns {*}
 */
export function selfwithdrawalapplication(params) {
  return request({
    url: `/finance/self_extract/list`,
    method: 'get',
    params
  })
}

/**
 *
 * 共享司机获取提现列表接口
 *
 * @returns {*}
 */
export function shared_extract(params) {
  return request({
    url: `/finance/shared_extract/list`,
    method: 'get',
    params
  })
}

/**
 *
 * 获取提现统计接口
 *
 * @returns {*}
 */
export function selfwithdrawalapplicationcount() {
  return request({
    url: `/finance/self_extract/count`,
    method: 'get',
  })
}
/**
 *
 * 共享司机获取提现统计接口
 *
 * @returns {*}
 */
export function shared_extractcount() {
  return request({
    url: `/finance/shared_extract/count`,
    method: 'get',
  })
}

/**
 *
 * 自营司机提现通过
 *
 * @returns {*}
 */
export function savepass(id) {
  return request({
    url: `/finance/self_extract/pass/${id}`,
    method: 'get',
  })
}

/**
 *
 * 自营司机提现拒绝
 *
 * @returns {*}
 */
export function savereject(id) {
  return request({
    url: `/finance/self_extract/reject/${id}`,
    method: 'post',
  })
}


/**
 *
 * 共享司机提现通过
 *
 * @returns {*}
 */
export function shared_extractpass(id) {
  return request({
    url: `/finance/shared_extract/pass/${id}`,
    method: 'get',
  })
}
////{id}
/**
 *
 * 共享司机提现拒绝
 *
 * @returns {*}
 */
export function shared_extractreject(id) {
  return request({
    url: `/finance/shared_extract/reject/${id}`,
    method: 'post',
  })
}



/**
 *
 * 运营 版本更新
 *
 * @returns {*}
 */
export function app_version(params) {
  return request({
    url: `/system/app_version`,
    method: 'get',
    params
  })
}


/**
 *
 * 运营 上传应用
 *
 * @returns {*}
 */
export function upload_app(device_type, data) {
  return request({
    url: `/system/app_version/upload_app/${device_type}`,
    method: 'post',
    data
  })
}

/**
 *
 * 运营 创建新包版本
 *
 * @returns {*}
 */
export function add_version(data) {
  return request({
    url: `/system/app_version`,
    method: 'post',
    data
  })
}


/**
 *
 * 运营 文件碎片上传
 *
 * @returns {*}
 */
export function upload_section(data) {
  return request({
    url: `/system/app_version/upload_section`,
    method: 'post',
    data
  })
}


/**
 *
 * 运营 文件碎片合并
 *
 * @returns {*}
 */
export function upload_merge_section(data) {
  return request({
    url: `/system/app_version/upload_merge_section`,
    method: 'post',
    data
  })
}


//账号管理

/**
 *
 * 超管账号管理列表
 *
 * @returns {*}
 */
export function get_superman(params) {
  return request({
    url: `/system/superman`,
    method: 'get',
    params
  })
}

//账号管理

/**
 *
 * 超管账号删除
 *
 * @returns {*}
 */

export function Delroles(id) {
  return request({
    url: `/system/superman/${id}`,
    method: 'delete',
  })
}

/**
 *
 * 超管账号修改状态
 *
 * @returns {*}
 */

export function Editstatus_zh(id) {
  return request({
    url: `/system/superman/${id}/status`,
    method: 'get',
  })
}

/**
 *
 * 超管账号添加
 *
 * @returns {*}
 */

export function add_sup(data) {
  return request({
    url: `/system/superman`,
    method: 'post',
    data
  })
}

/**
 *
 * 修改超管账号
 *
 * @returns {*}
 */

export function xiu_sup(id, data) {
  return request({
    url: `/system/superman/${id}`,
    method: 'put',
    data
  })
}

//获取下拉
export function select_data() {
  return request({
    url: `/system/roles_group/role_select`,
    method: 'get',
  })
}

//获取会员配置
export function v2_platform() {
  return request({
    url: `/v2/operation/member/platform/config`,
    method: 'get',
  })
}

//获取会员列表
export function huiyuan_list(params) {
  return request({
    url: `/v2/operation/member/platform/list`,
    method: 'get',
    params
  })
}

//平台会员添加
export function huiyuan_add(data) {
  return request({
    url: `/v2/operation/member/platform/create`,
    method: 'post',
    data
  })
}

//平台会员详情
export function huiyuan_detail(id) {
  return request({
    url: `/v2/operation/member/platform/details/${id}`,
    method: 'get',
  })
}

//平台会员编辑
export function huiyuan_xiugai(id, data) {
  return request({
    url: `/v2/operation/member/platform/put/${id}`,
    method: 'put',
    data
  })
}

//平台会员状态修改
export function huiyuan_status(id) {
  return request({
    url: `/v2/operation/member/platform/status/${id}`,
    method: 'get',
  })
}

//平台会员删除
export function huiyuan_del(id) {
  return request({
    url: `/v2/operation/member/platform/delete/${id}`,
    method: 'delete',
  })
}


//获取树形菜单
export function get_menu() {
  return request({
    url: `/v2/operation/member/platform/get_menu_list`,
    method: 'get',
  })
}

//免费会员修改
export function free_huiyuan_xiugai(data) {
  return request({
    url: `/v2/operation/member/platform/free_nember_edit`,
    method: 'post',
    data
  })
}

//获取免费会员详情
export function free_huiyuan_detail() {
  return request({
    url: `/v2/operation/member/platform/free_nember_set`,
    method: 'get',

  })
}


//获取司机会员列表
export function siji_list(params) {
  return request({
    url: `/v2/operation/member/driver/list`,
    method: 'get',
    params
  })
}

//获取司机会员详情
export function siji_deatil(id) {
  return request({
    url: `/v2/operation/member/driver/details/${id}`,
    method: 'get',
  })
}

//获取司机会员配置
export function siji_config() {
  return request({
    url: `/v2/operation/member/driver/config`,
    method: 'get',
  })
}

//司机会员添加
export function siji_add(data) {
  return request({
    url: `/v2/operation/member/driver/create`,
    method: 'post',
    data
  })
}

//司机会员修改
export function siji_xiugai(id, data) {
  return request({
    url: `/v2/operation/member/driver/put/${id}`,
    method: 'put',
    data
  })
}




//司机会员状态修改
export function si_status(id) {
  return request({
    url: `/v2/operation/member/driver/status/${id}`,
    method: 'get',
  })
}

//司机会员删除
export function si_del(id) {
  return request({
    url: `/v2/operation/member/driver/delete/${id}`,
    method: 'delete',
  })
}

/**
 * 服务商员工推广关系记录
 * @returns {*}
 */
export function fws_his (params) {
  return request({
    url: `/operation/spread_concern`,
    method: 'get',
    params
  })
}






























































































